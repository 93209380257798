import React, {Fragment, useContext, useEffect} from 'react'
import './styles.css';
import Button from "@mui/material/Button";
import {Hidden, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import Box from "@mui/material/Box";
import {AuthContext, PageContext} from "@lb/mlib-components";
import CallIcon from '@mui/icons-material/Call';
import Grid from "@mui/material/Grid";

export default function LoggedInHome() {

    const authCtx = useContext(AuthContext);

    const pageCtx = useContext(PageContext);

    useEffect(() => pageCtx.updateTitle(""), [pageCtx])

    const address = <Box marginTop={5}>
        <Typography variant={"h6"} paddingBottom={1}>Mailing Address</Typography>
        <Typography>#120 - 1777 Clearbrook Rd.</Typography>
        <Typography>Abbotsford, BC V2T 5X5</Typography>
        <Typography>Canada</Typography>
        {authCtx.isLoggedIn() ? <Button sx={{marginTop: 2, paddingLeft: 0, color: '#0069b1'}} href="tel:+1-800-604-0343"
                                        startIcon={<CallIcon/>}>
                +1 (800) 604-0343
            </Button> :
            <Button sx={{marginTop: 2, paddingLeft: 0, color: 'white'}} href="tel:+1-800-604-0343"
                    startIcon={<CallIcon/>}>
                +1 (800) 604-0343
            </Button>}
    </Box>;


    return <div className={'WrapperLoggedIn'}><Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{minHeight: '80vh'}}
        >
            <Grid item xs='auto'>{address}</Grid>
        </Grid></div>

}