import React, {useCallback, useContext, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';

import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import {Divider, IconButton, Stack} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import logo from '../../InsideLongboardColourLogo.png';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import {AuthContext, CheckRoles, Loader, logout, PageContext, refreshToken, toast} from "@lb/mlib-components";
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Button from "@mui/material/Button";
import jwtDecode from "jwt-decode";
import {AutoFixHigh, HowToReg} from "@mui/icons-material";

export const getInitials = (name, maxLength = 3) => {

    const chars = [...name.trim()];

    if (name.length <= maxLength) return name;

    const initials = [];

    for (const [idx, char] of chars.entries()) {
        if (
            char.toLowerCase() !== char ||
            !chars[idx - 1] ||
            /\s/.test(chars[idx - 1])
        ) {
            initials.push(char);

            if (initials.length === maxLength) break;
        }
    }

    return initials.join('');
};
const DesktopHeader = () => {
    const navigate = useNavigate();

    const pageCtx = useContext(PageContext);
    const authCtx = useContext(AuthContext);

    const logoutHandler = useCallback(() => {

        logout(authCtx.accessToken).catch(error => error).finally(() => {
            handleClose()
            authCtx.clear()
            toast.success('Successfully logged out')
        })

    }, [authCtx])

    const userName = authCtx.userName;
    const title = pageCtx.title;

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);


    function goToLinksManagementPage() {
        navigate('/linksmanagement');
    }

    function goToBlogManagementPage() {
        navigate('/blog-management/categories');
    }

    function goToEventManagementPage() {
        navigate('/volunteer-management/events');
    }

    const {build} = window['runConfig'];

    let color;

    if (build === "Production")
        color = '#2a2f34'
    else
        color = '#37b6ff'

    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [accessContent, setAccessContent] = useState([])

    const refreshTokens = useCallback(() => {
        setOpenBackdrop(true)

        refreshToken(authCtx.refreshToken).then(token => {
            const accessTokenDecoded = jwtDecode(token.access_token)

            authCtx.update(
                accessTokenDecoded.name,
                token.access_token,
                accessTokenDecoded.exp,
                token.refresh_token,
                token.refresh_token_exp,
                accessTokenDecoded.azp,
                {
                    realm_access: accessTokenDecoded.realm_access,
                    resource_access: accessTokenDecoded.resource_access
                }
            )

            setAccessContent(authCtx.readRoles())

            setOpenModal(true)
        }).finally(() => setOpenBackdrop(false))
    }, [authCtx])

    return (
        <AppBar style={{background: color}} position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
            <Loader active={openBackdrop}/>
            <CheckRoles
                open={openModal}
                onClose={() => setOpenModal(false)}
                roles={accessContent}
            />
            <header style={{
                height: "3rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0% 1%"
            }}>
                <Link to='/'>
                    <div><img style={{width: "10rem"}} src={logo} alt={"Inside App"}/>
                    </div>
                </Link>
                <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
                    <Typography
                        color="inherit" variant="h5"
                        component="div">
                        {title}
                    </Typography>
                    {pageCtx.title === "Links" && authCtx.hasRole("links") &&
                        <Button variant={"contained"} size={"small"} onClick={goToLinksManagementPage}>Manage</Button>}
                    {pageCtx.title !== "Blog Management" && pageCtx.title.split(' ')[0] === "Blog" && authCtx.hasRole('blogs') &&
                        <Button variant={"contained"} size={"small"} onClick={goToBlogManagementPage}>Manage</Button>}
                    {pageCtx.title === "Volunteer" && authCtx.hasRole('volunteer') &&
                        <Button variant={"contained"} size={"small"} onClick={goToEventManagementPage}>Manage</Button>}
                </Stack>

                <div>
                    <IconButton
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                    >
                        <Avatar>
                            {getInitials(userName)}
                        </Avatar>
                        <Typography sx={{color: "#FFFFFF", paddingLeft: "1rem"}} variant="subtitle1">
                            {userName}
                        </Typography>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                    >

                        <MenuItem onClick={refreshTokens}>
                            <ListItemIcon>
                                <HowToReg fontSize="small"/>
                            </ListItemIcon>
                            Check Roles
                        </MenuItem>
                        <Divider/>
                        <MenuItem onClick={() => window.location.reload()}>
                            <ListItemIcon>
                                <AutoFixHigh fontSize="small"/>
                            </ListItemIcon>
                            Fix My Problems
                        </MenuItem>

                        <Divider/>
                        <MenuItem onClick={logoutHandler}>
                            <ListItemIcon>
                                <Logout fontSize="small"/>
                            </ListItemIcon>
                            Logout
                        </MenuItem>

                    </Menu>
                </div>

            </header>
        </AppBar>
    );
};

export default DesktopHeader;
