import ReactGA from "react-ga4";

export function trackTabChange (tabName) {
    console.log(tabName)
  ReactGA.event({
      category: 'Tab',
      action: 'Click',
      label: tabName
  });
}

export function trackScreen (screenTitle) {
  ReactGA.send({hitType: 'pageview', page: screenTitle});
}