import {LicenseInfo} from '@mui/x-data-grid-pro';

import React from 'react'

import './app.css'
import InsideRoutes from "./routes";

import ReactGA from "react-ga4";
import {createTheme} from '@mui/material/styles';
import MlibComponents, {themeCustomization} from "@lb/mlib-components";

LicenseInfo.setLicenseKey(
    '4c3b5b3788403eca3d834d25119716a4Tz03MzM5NyxFPTE3MjQ1Mzk3MzkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

ReactGA.initialize("G-NR0CBHSMN3")

export const dataGridProStateVersion = 5

function App() {

    return (

        <MlibComponents>
            <InsideRoutes/>
        </MlibComponents>
    )
}

export default App

