import {LicenseInfo} from '@mui/x-data-grid-pro';
import React, {lazy, Suspense, useCallback, useContext, useEffect} from 'react';

import './app.css'
import {AuthContext, Forbidden, Loader, NotFound, PageContext} from "@lb/mlib-components";
import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import TV from "./pages/management/easter_egg_hunt/TV";
import {trackScreen} from "./utils/reactGA";
import {LoggedInLayout} from "./components/layout/LoggedInLayout";
import Home from "./pages/home/home";
import LoggedInHome from "./pages/home/LoggedInHome";

const Login = lazy(() => import('./pages/login/login'));
const PhoneBook = lazy(() => import('./pages/phone_book/phone_book'));
const LinksList = lazy(() => import('./pages/links_management/LinksList'));
const BlogManagement = lazy(() => import('./pages/blog/blog_management/BlogManagement'));
const ComposePost = lazy(() => import('./pages/blog/ComposePost'));
const Blog = lazy(() => import('./pages/blog/blog'));
const Links = lazy(() => import('./pages/links'));
const Calendar = lazy(() => import('./pages/calendar/calendar'));
const EasterEggHunt = lazy(() => import('./pages/management/easter_egg_hunt/EasterEggHunt'));
const EasterEgg = lazy(() => import('./pages/management/easter_egg_hunt/EasterEgg'));
const Volunteer = lazy(() => import('./pages/volunteer/Volunteer'));
const EventManagement = lazy(() => import('./pages/volunteer/volunteer_management/VolunteerManagement'));
const EventInfo = lazy(() => import('./pages/volunteer/EventInfo'));

LicenseInfo.setLicenseKey(
    'f8ecce0550e110c5b3a57de564146ba6T1JERVI6Mjk3NjEsRVhQSVJZPTE2NjM4NzE4MjcwMDAsS0VZVkVSU0lPTj0x',
);

export default function InsideRoutes(props) {
    const authCtx = useContext(AuthContext)
    const location = useLocation()

    // track location(url-pathname) change to kick out user without api call
    const isLoggedIn = useCallback(() => location && authCtx.isLoggedIn(), [authCtx, location])

    const ProtectedRoute = useCallback(({isAllowed, redirectPath, children}) => {
        if (isAllowed) return <Outlet/>
        else {
            if (redirectPath) return <Navigate to={redirectPath} replace/>
            else return <Forbidden/>
        }
    }, [])

    const pageCtx = useContext(PageContext);

    const checkStringAfterLogin = () => {
        const path = location.pathname;
        const loginIndex = path.indexOf('/login/');
        return loginIndex !== -1 && path.length > loginIndex + 7;
    };

    useEffect(() => {
        if (checkStringAfterLogin() === false) {
            trackScreen(pageCtx.title + ' - ' + location.pathname);
        }
    }, [pageCtx.title]);

    useEffect(() => {
        if (window.location.pathname !== '/login')
            pageCtx.updateCurrentUrl(window.location.pathname)
    }, [pageCtx])

    function DynamicHomeRoute() {
        return isLoggedIn()
            ? <LoggedInLayout mainPadding={0}><LoggedInHome/></LoggedInLayout>
            : <Home/>;
    }

    return (<Suspense fallback={<Loader active/>}>
            <Routes>
                <Route path='/tv' element={<TV/>}> </Route>
                {/*<Route path='easteregghunt/:code' element={<EasterEgg/>}/>*/}

                <Route path="/" element={<DynamicHomeRoute/>}/>

                {/* `Not logged in` routes */}

                <Route element={<ProtectedRoute isAllowed={!isLoggedIn()} redirectPath='/'/>}>

                    <Route path='login'>
                        <Route index element={<Login/>}/>
                        <Route path=':token' element={<Login/>}/>
                    </Route>

                </Route>

                {/* `Logged in` routes*/}
                <Route element={<ProtectedRoute isAllowed={isLoggedIn()} redirectPath='login'/>}>

                    <Route path='/calendar' element={<LoggedInLayout><Calendar/></LoggedInLayout>}/>
                    <Route path='/phonebook' element={<LoggedInLayout><PhoneBook/></LoggedInLayout>}/>
                    <Route path='/links' element={<LoggedInLayout mainPadding={0}><Links/></LoggedInLayout>}/>

                    <Route element={<ProtectedRoute isAllowed={authCtx.hasRole('links')}/>}>
                        <Route path='linksmanagement' element={<LoggedInLayout><LinksList/></LoggedInLayout>}/>
                    </Route>

                    {/*<Route element={<ProtectedRoute isAllowed={authCtx.hasRole('easter')}/>}>
                        <Route path='easteregghunt'>
                            <Route index element={<EasterEggHunt/>}/>
                        </Route>
                    </Route>*/}


                    <Route path='blog'>

                        <Route index element={<LoggedInLayout mainPadding={0}><Blog/></LoggedInLayout>}/>

                        <Route path=':categoryid'>
                            <Route path=':pageid'>

                                <Route index element={<LoggedInLayout><ComposePost readOnly/></LoggedInLayout>}/>

                                <Route element={<ProtectedRoute isAllowed={authCtx.hasRole('blogs')}/>}>
                                    <Route path='compose'
                                           element={<LoggedInLayout><ComposePost readOnly={false}/></LoggedInLayout>}/>
                                </Route>

                            </Route>
                        </Route>

                    </Route>

                    <Route element={<ProtectedRoute isAllowed={authCtx.hasRole('blogs')}/>}>

                        <Route path='blog-management'>
                            <Route path='categories'
                                   element={<LoggedInLayout mainPadding={0}><BlogManagement
                                       tab={"categories"}/></LoggedInLayout>}/>
                            <Route path='pages'
                                   element={<LoggedInLayout mainPadding={0}><BlogManagement
                                       tab={"pages"}/></LoggedInLayout>}/>
                        </Route>

                    </Route>

                    <Route path='volunteer' element={<LoggedInLayout mainPadding={0}><Volunteer/></LoggedInLayout>}/>
                    <Route path='events'>
                        <Route path=':eventid' element={<LoggedInLayout><EventInfo readOnly/></LoggedInLayout>}/>
                    </Route>

                    <Route element={<ProtectedRoute isAllowed={authCtx.hasRole('volunteer')}/>}>
                        <Route path='volunteer-management'>
                            <Route path='events'>
                                <Route index
                                       element={<LoggedInLayout mainPadding={0}><EventManagement/></LoggedInLayout>}/>
                                <Route path=':eventid' element={<LoggedInLayout><EventInfo/></LoggedInLayout>}/>
                            </Route>
                        </Route>
                    </Route>

                    <Route path="login" element={<Navigate to="/" replace/>}/>

                </Route>

                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Suspense>
    )
}

