import React, {useCallback, useContext, useState} from "react";


import {instance, PageContext} from "@lb/mlib-components";
import './styles.css'
import {ListItemAvatar, ListItemButton, Stack, Typography} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {getInitials} from "../../../components/layout/DesktopHeader";
import {tsToDateStrTime} from "../../../utils/getMomentDate";

export default function TV(props) {

    const pageCtx = useContext(PageContext);
    pageCtx.updateTitle("Easter Egg Hunt Scoring");

    const [loading, setLoading] = useState(false);
    const [scores, setScores] = useState([]);


    const {apiUrl} = window['runConfig'];

    const loadScoresFromServer = useCallback(() => {
        setLoading(true)

        let finalApiUrl = apiUrl + '/easteregghunt/leaders/5';

        instance.get(finalApiUrl).then((response) => {

            const rows = response.data.leaders

            setScores(rows);

            setLoading(false);

        }).finally(() => {
            setLoading(false);
        });

    }, [apiUrl]);


    React.useEffect(() => {
        loadScoresFromServer();
    }, [loadScoresFromServer]);

    React.useEffect(() => {
        const interval = setInterval(() => {
            loadScoresFromServer();
        }, 3000);
        return () => clearInterval(interval);
    }, [loadScoresFromServer]);

    const themeDark = React.useMemo(() => createTheme(), [],);

    let theme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    theme.typography.body1 = {
        fontSize: '1rem',
        '@media (min-width:3000px)': {
            fontSize: '3rem',
        },
    };


    return <ThemeProvider theme={themeDark}>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {/*<Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                    open={loading}
                >
                    <CircularProgress color="inherit"/>
                </Backdrop>*/}
            <div className={'WrapperTV'}>

                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{minHeight: '100vh'}}
                >
                    <Typography variant={'h5'} sx={{
                        fontSize: window.innerWidth > 3000 ? 200 : 60,
                        color: 'white',
                        marginBottom: window.innerWidth > 3000 ? 5 : 2
                    }}>Easter Egg Hunt
                        Scoring</Typography>
                    <Divider color={"#ffffff"} sx={{height: window.innerWidth > 3000 ? 5 : 2, width: '80vw'}}/>
                    <Box margin={window.innerWidth > 3000 ? 8 : 2}></Box>
                    <List sx={{width: '100%', maxWidth: '80vw', color: 'white'}}>
                        {scores?.map((value, index) => {
                            return (
                                <Box margin={window.innerWidth > 3000 ? 5 : 0}>
                                    <ListItem
                                        key={value}
                                        secondaryAction={
                                            <Stack direction={'row'} spacing={2} alignItems={"center"}>
                                                <Typography variant={'h5'}
                                                            sx={{fontSize: window.innerWidth > 3000 ? 120 : 30}}>
                                                    {value.egg_count} {value.egg_count === 1 ? 'egg' : 'eggs'}
                                                </Typography>
                                                <Typography variant={'h6'}
                                                            sx={{
                                                                fontWeight: 'light',
                                                                fontSize: window.innerWidth > 3000 ? 120 : 30
                                                            }}>
                                                    (last found at {tsToDateStrTime({value: value.last_egg_found_at})})
                                                </Typography>
                                            </Stack>
                                        }
                                        disablePadding
                                    >
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar sx={{
                                                    width: window.innerWidth > 3000 ? 200 : 70,
                                                    height: window.innerWidth > 3000 ? 200 : 70
                                                }}>
                                                    <Typography variant={'h4'} sx={{
                                                        fontSize: window.innerWidth > 3000 ? 100 : 30,
                                                        color: 'white'
                                                    }}>
                                                        {getInitials(value.full_name, 2)}
                                                    </Typography>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <Typography variant={'h4'} sx={{
                                                fontSize: window.innerWidth > 3000 ? 100 : 30,
                                                marginLeft: window.innerWidth > 3000 ? 10 : 2
                                            }}>
                                                {value.full_name.toUpperCase()}
                                            </Typography>

                                        </ListItemButton>
                                    </ListItem>
                                </Box>
                            );
                        })}
                    </List>

                </Grid>
            </div>
        </ThemeProvider>
    </ThemeProvider>
}
;