import React, {useCallback, useContext} from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import {AuthContext, Loader, logout, toast} from "@lb/mlib-components";

export function handleFixMyProblems() {
    window.location.reload()
    for (let i = localStorage.length - 1; i >= 0; i--) {
        const key = localStorage.key(i);
        if (key.includes('PersistDataGridPro')) {
            localStorage.removeItem(key);
        }
    }
}


const SettingsActions = () => {

    const authCtx = useContext(AuthContext)
    const [openBackdrop, setOpenBackdrop] = React.useState(false);


    const logoutHandler = useCallback(() => {
        setOpenBackdrop(true)

        logout(authCtx.accessToken).catch(error => error).finally(() => {
            setOpenBackdrop(false)
            authCtx.clear()
            toast.success('Successfully logged out')
        })

    }, [authCtx])

    return (<>

            <MenuItem onClick={logoutHandler}>
                <ListItemIcon>
                    <PowerSettingsNewIcon fontSize="small"/>
                </ListItemIcon>
                Logout
            </MenuItem>
            <Loader active={openBackdrop}/>

        </>
    )
};

export default SettingsActions;
