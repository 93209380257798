import moment from "moment";

export function getMomentDate(params) {
    return params.value !== null ? moment.utc(moment.unix(+params.value)).isSame(moment(), 'day') ? "today" : moment.utc(moment.unix(+params.value)).fromNow() : "";
}

export function getDate(params) {
    return params.value !== null ? moment.utc(moment.unix(+params.value)).isSame(moment(), 'day') ? "today" : moment.utc(moment.unix(+params.value)).calendar() : "";
}

export function tsToDateStr(timestamp) {
    return (moment.unix(timestamp).format("MMM D, YYYY"))
}

export function tsToDateStrTime(params) {
    return (moment.unix(params.value).format("HH:mm"))
}