import PagesMenu from "./PagesMenu";
import SettingsActions from "./SettingsActions";
import React from "react";
import {Header, Layout} from "@lb/mlib-components";
import logo from "../../InsideLongboardColourLogo.png";

export const LoggedInLayout = ({mainPadding, children}) => {

    const {version, build} = window['runConfig'];

    return (
        <Layout build={build} version={version} drawer={<PagesMenu/>}
                appBar={<Header logo={logo} logoAlt={'Inside App'} title={'The People of Longboard'}
                                actions={<SettingsActions/>}/>} mainPadding={mainPadding}>
            {children}
        </Layout>
    );
};