import React, {Fragment, useContext} from "react";
import List from "@mui/material/List";
import {useNavigate} from "react-router-dom";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import EventIcon from '@mui/icons-material/Event';
import CreateIcon from '@mui/icons-material/Create';
import LinkIcon from '@mui/icons-material/Link';
import {AuthContext, PageContext, PagesMenuItem} from "@lb/mlib-components";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import {IconButton} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

export default function PagesMenu(props) {
    const navigate = useNavigate();
    const pageCtx = useContext(PageContext);

    const authCtx = useContext(AuthContext);

    return <Fragment>
        <List>
            <PagesMenuItem main text="Calendar" to="/calendar" icon={EventIcon}/>
            <PagesMenuItem main text="Phone Book" to="/phonebook" icon={ContactPhoneIcon}/>
            <PagesMenuItem main text="Links" to="/links" icon={LinkIcon}
                           actions={
                               authCtx.hasRole('links') &&
                               <IconButton variant={"outlined"} size={"small"}
                                           onClick={(event) => {
                                               event.stopPropagation()
                                               event.preventDefault()
                                               navigate('/linksmanagement')
                                               pageCtx.setMobileDrawerOpen(false)
                                           }}>
                                   <SettingsIcon fontSize={"small"} color={'primary'}/>
                               </IconButton>
                           }
            />
            <PagesMenuItem main text="Blog" to="/blog" icon={CreateIcon}  actions={
                               authCtx.hasRole('blogs') &&
                               <IconButton variant={"outlined"} size={"small"}
                                           onClick={(event) => {
                                               event.stopPropagation()
                                               event.preventDefault()
                                               navigate('/blog-management/categories')
                                               pageCtx.setMobileDrawerOpen(false)

                                           }}>
                                   <SettingsIcon fontSize={"small"} color={'primary'}/>
                               </IconButton>
                           }/>
            <PagesMenuItem main text="Volunteer" to="/volunteer" icon={VolunteerActivismIcon}
                           actions={
                               authCtx.hasRole('volunteer') &&
                               <IconButton variant={"outlined"} size={"small"}
                                           onClick={(event) => {
                                               event.stopPropagation()
                                               event.preventDefault()
                                               navigate('/volunteer-management/events')
                                               pageCtx.setMobileDrawerOpen(false)

                                           }}>
                                   <SettingsIcon fontSize={"small"} color={'primary'}/>
                               </IconButton>
                           }
            />
            {/* {authCtx.hasRole('easter') && 
        <PagesMenuItem main onClick={handleClose}text="Easter Egg Hunt" to="/easteregghunt" icon={EggIcon} />
    } */}
        </List>


    </Fragment>
}