import React, {useContext, useEffect} from 'react'
import {LandingPage, PageContext} from "@lb/mlib-components";
import logo from "../../InsideLongboardColourLogo.png";
import {Link} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {styled} from "@mui/material/styles";

export default function Home(props) {

    const pageCtx = useContext(PageContext);

    useEffect(() => {
        pageCtx.updateTitle("");
    }, [pageCtx])

    const EmployeeAccessButton = styled('div')(() => ({
        display: 'inline-flex',
        width: '130px',
        padding: '.85em 1.5em',
        fontSize: '15px',
        lineHeight: '1em',
        textDecoration: 'none',
        border: '2px solid white',
        borderRadius: '50em',
        color: 'white',
        transition: 'all .3s',
    }));


    return <LandingPage logo={<Link to='/'>
        <div style={{marginBottom: 20}}>
            <img style={{width: 200}}
                 src={logo} alt={'Inside App'}/>
        </div>
    </Link>} logoAlt={'Inside App'} title={'The People of Longboard'}
                        loginButtons={<Link to="/login"><EmployeeAccessButton>Employee
                            Access</EmployeeAccessButton></Link>}/>;
}