import React from 'react';
import * as ReactDOM from 'react-dom';
import {StyledEngineProvider} from "@mui/material";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import {GoogleOAuthProvider} from "@react-oauth/google";
import {BrowserRouter} from "react-router-dom";


const providers = [
    (props) => <StyledEngineProvider {...props} injectFirst/>,
    ({children, ...rest}) => <GoogleOAuthProvider
        clientId={'674869190121-bqu1soou4ad2bk4frpbl8olus8bjv29e.apps.googleusercontent.com'}
        {...rest} children={children}/>
];

function renderApp() {
    let content = <App/>;
    [...providers].reverse().map(Component => content = <Component>{content}</Component>);
    return <BrowserRouter>{content}</BrowserRouter>;
}

// // React 18 introduces a new root API which provides better ergonomics for managing roots.
// const container = document.getElementById('root');
// const root = createRoot(container)
// root.render(<React.StrictMode>{renderApp()}</React.StrictMode>)

ReactDOM.render(renderApp(), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

//serviceWorker.unregister();

serviceWorkerRegistration.register({
    onUpdate: registration => {
        document.dispatchEvent(new CustomEvent('serviceWorkerUpdate', {detail: registration}));
    }
});
